import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const MAX_IMAGES_COUNT = 7;

const images = [
    { size: 50, yOffset: -25, xOffset: -10 },
    { size: 140, yOffset: 100, xOffset: 0 },
    { size: 30, yOffset: -25, xOffset: 0 },
    { size: 65, yOffset: 40, xOffset: 25 },
    { size: 30, yOffset: 130, xOffset: 20 },
    { size: 120, yOffset: -25, xOffset: 0 },
    { size: 90, yOffset: 130, xOffset: 0 },
];


const Team = ({ data }) => {
    const [currSliceIdx, setCurrSliceIdx] = useState(0);
    const slicedData = [];
    for (let i = 0; i < data.length; i += MAX_IMAGES_COUNT) {
        slicedData.push(data.slice(i, i + MAX_IMAGES_COUNT));
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (currSliceIdx + 1 === slicedData.length) {
                setCurrSliceIdx(0);
            } else {
                setCurrSliceIdx((prev) => prev + 1);
            }
        }, 3000);
        return () => clearTimeout(timerId);
    }, [currSliceIdx, slicedData])

    console.log('aaa ',{ slicedData, currSliceIdx });

    return (
        <div className="About__team">
            <div className="About__team_spots">
                <img
                    className="About__team_spots-lg"
                    src="img/spot_lg.png"
                    alt="big spot" />
                <img
                    className="About__team_spots-sm"
                    src="img/spot_sm.png"
                    alt="small spot" />
            </div>
            <div className="About__team_avatars">
                {slicedData[currSliceIdx]
                    .map((e, i) => {
                        const {
                            size = 30,
                            yOffset = 0,
                            xOffset = 0,
                        } = images[i] || {};
                        return (
                            <figure
                                initial={{ opacity: 0, y: 200 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                key={i}
                                style={{
                                    transform: `translate(${xOffset}px, ${yOffset}px)`,
                                }}>
                                <img
                                    src={e.image.publicURL}
                                    alt={e.title}
                                    style={{
                                        width: size,
                                        height: size,
                                    }} />
                                <figcaption
                                    style={{ maxWidth: size, }}>
                                    {e.position}
                                </figcaption>
                            </figure>
                        )
                    })}
            </div>

        </div >
    )
};

Team.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            title: PropTypes.string,
        })
    ),
}

export default Team;
