import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Team from '../components/Team'

export const AboutPageTemplate = ({ title, content, contentComponent, ...frontmatter }) => {
  const PageContent = contentComponent || Content
  return (
    <div className='About'>
      <section className="About__main">
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <PageContent className="content" content={content} />
      </section>
      <Team data={frontmatter.employees.list} />
      <section className="About__secondary">
        <h3 dangerouslySetInnerHTML={{ __html: frontmatter.methodology.title }} />
        <p dangerouslySetInnerHTML={{ __html: frontmatter.methodology.content }} />
      </section>
      <section className="About__secondary">
        <h3 dangerouslySetInnerHTML={{ __html: frontmatter.mission.title }} />
        <p dangerouslySetInnerHTML={{ __html: frontmatter.mission.content }} />
      </section>
    </div>

  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout showFooterBG={false}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        content={html}
        {...frontmatter}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        methodology { 
          title
          content
        }
        mission { 
          title
          content
        }
        employees {
          list {
            image {
              publicURL
            }
            position
          }
        }
      }
    }
  }
`
